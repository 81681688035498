import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { RouteObject } from 'react-router';
import { ProfilePage } from 'views/pages/profile';
import AllowedTo from './access/AllowedTo';
import BlockedTo from './access/BlockedTo';
import RequireAuth from './access/RequireAuth';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const DataTablePage = Loadable(lazy(() => import('views/utilities/DataTable')));
const FormPage = Loadable(lazy(() => import('views/Form/Form')));
const ListCustomersPage = Loadable(lazy(() => import('views/pages/customers')));
const PartnersPage = Loadable(lazy(() => import('views/pages/partners')));
const ContractsPage = Loadable(lazy(() => import('views/pages/contracts')));
const PlansPage = Loadable(lazy(() => import('views/pages/plans')));
const DistributorsPage = Loadable(lazy(() => import('views/pages/distributors')));
const ReportsPage = Loadable(lazy(() => import('views/pages/reports')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes: RouteObject = {
  path: '/',
  element: (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  ),
  children: [
    {
      path: '/',
      element: (
        <BlockedTo roles={['USER']}>
          <DashboardDefault />
        </BlockedTo>
      )
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: (
            <BlockedTo roles={['USER']}>
              <DashboardDefault />
            </BlockedTo>
          )
        }
      ]
    },
    {
      path: 'users',
      children: [
        {
          path: 'list',
          element: (
            <AllowedTo roles={['ADMIN']}>
              <ListCustomersPage />
            </AllowedTo>
          )
        }
      ]
    },
    {
      path: 'partners',
      children: [
        {
          path: 'list',
          element: (
            <AllowedTo roles={['ADMIN', 'OPERATOR', 'FINANCIAL', 'COMMERCIAL']}>
              <PartnersPage />
            </AllowedTo>
          )
        }
      ]
    },
    {
      path: 'distributors',
      children: [
        {
          path: 'list',
          element: <DistributorsPage />
        }
      ]
    },
    {
      path: 'plans',
      children: [
        {
          path: 'list',
          element: (
            <AllowedTo roles={['ADMIN', 'OPERATOR', 'FINANCIAL', 'COMMERCIAL']}>
              <PlansPage />
            </AllowedTo>
          )
        }
      ]
    },
    {
      path: 'contracts',
      children: [
        {
          path: 'list',
          element: (
            <AllowedTo roles={['ADMIN', 'OPERATOR', 'FINANCIAL', 'COMMERCIAL']}>
              <ContractsPage />
            </AllowedTo>
          )
        }
      ]
    },
    {
      path: 'reports',
      children: [
        {
          path: 'list',
          element: (
            <AllowedTo roles={['ADMIN', 'OPERATOR']}>
              <ReportsPage />
            </AllowedTo>
          )
        }
      ]
    },
    {
      path: 'profile',
      element: <ProfilePage />
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'datatable',
          element: <DataTablePage />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'form',
          element: <FormPage />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    }
  ]
};

export default MainRoutes;
